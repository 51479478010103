import {
  PermissionType,
  CompanyPreference,
  Locale,
  PayrollSystem,
  Country,
} from '@/constants'
import { IUserCompanyInfoWithPermissions, CompanySettings } from '@/models'
import useAppStore from '@/state/store'
import dayjs from 'dayjs'
import { enqueueLoadedSnackbar } from './snackbar'
import logger from './logger'
import { checkAndRefreshToken } from './auth'
import { CoreApiError } from '@loadedhub/core-api-sdk'

export const constructActiveCompanySettings = (
  activeCompany: IUserCompanyInfoWithPermissions | undefined,
) => {
  const preference = (preferenceName: CompanyPreference) => {
    if (!activeCompany?.preferences) {
      logger.warn('No preferences in active company')
      return null
    }

    const preference = activeCompany.preferences[preferenceName]
    if (!preference) {
      logger.warn(
        'No preference with name %s in active company',
        preferenceName,
      )
      return null
    }

    return preference
  }

  const hasPermission = (permissionName: PermissionType) => {
    if (!activeCompany?.permissions || !activeCompany.permissionsList) {
      logger.warn('No permissions in active company')
      return false
    }

    return (
      activeCompany.permissionsList.some(
        (permission) =>
          permission.toLowerCase() === permissionName.toLowerCase(),
      ) ?? false
    )
  }

  const hasTimePermission = hasPermission(PermissionType.Time) ?? false

  const hasSalaryPermission = hasPermission(PermissionType.Salary) ?? false

  const hasPayrollPermission = hasPermission(PermissionType.Payroll) ?? false

  const hasManagerPermission = hasPermission(PermissionType.Manager) ?? false

  const hasStockPermission = hasPermission(PermissionType.Stock) ?? false

  const weekStartPreference: number = preference(CompanyPreference.WeekStart)
    ? +preference(CompanyPreference.WeekStart)!
    : 0

  const dayStartPreference: string =
    preference(CompanyPreference.DayStart) ?? '05:00:00'

  const localePreference: Locale =
    (preference(CompanyPreference.Locale) as Locale) ?? Locale.NZ

  const timeZonePreference: string =
    preference(CompanyPreference.TimeZone) ?? dayjs.tz.guess()

  const payrollSystemPreference: PayrollSystem | null = preference(
    CompanyPreference.PayrollSystem,
  ) as PayrollSystem | null

  const defaultFreightTaxRatePreference: string | null = preference(
    CompanyPreference.DefaultFreightTaxRate,
  )

  const clockinRoundingPreference: string | null = preference(
    CompanyPreference.ClockinRounding,
  )

  const overrideSalesTaxRatePreference: boolean =
    preference(CompanyPreference.OverrideSalesTaxRate) === '1'

  const includePosClockinsPreference: boolean =
    preference(CompanyPreference.IncludePosClockins) === '1'

  const timeAllowsPaySummaryPreference: boolean =
    preference(CompanyPreference.TimePermissionAllowsWageAndSalarySummary) ===
    '1'

  const countryPreference: Country =
    getCountryPreferenceBasedOnLocale(localePreference)

  return {
    countryPreference,
    weekStartPreference,
    dayStartPreference,
    localePreference,
    timeZonePreference,
    payrollSystemPreference,
    hasPayrollPermission,
    hasManagerPermission,
    hasTimePermission,
    hasSalaryPermission,
    hasStockPermission,
    defaultFreightTaxRatePreference,
    overrideSalesTaxRatePreference,
    includePosClockinsPreference,
    timeAllowsPaySummaryPreference,
    clockinRoundingPreference,
  }
}

export const getCountryPreferenceBasedOnLocale = (localePreference: Locale) => {
  let countryPreference: Country
  switch (localePreference) {
    case Locale.AU:
      countryPreference = Country.Australia
      break
    case Locale.NZ:
    default:
      countryPreference = Country.NewZealand
      break
  }
  return countryPreference
}

export const getActiveCompanySettings = (): CompanySettings => {
  const activeCompany = useAppStore.getState().activeCompany

  const companySettings = constructActiveCompanySettings(activeCompany)

  return {
    address: activeCompany?.address,
    name: activeCompany?.name,
    pos: activeCompany?.pos,
    invoiceEmail: activeCompany?.invoiceEmail,
    permissionsList: activeCompany?.permissionsList ?? [],
    countryPreference: companySettings.countryPreference,
    weekStartPreference: companySettings.weekStartPreference,
    dayStartPreference: companySettings.dayStartPreference,
    localePreference: companySettings.localePreference,
    timeZonePreference: companySettings.timeZonePreference,
    payrollSystemPreference: companySettings.payrollSystemPreference,
    hasPayrollPermission: companySettings.hasPayrollPermission,
    hasManagerPermission: companySettings.hasManagerPermission,
    hasTimePermission: companySettings.hasTimePermission,
    hasSalaryPermission: companySettings.hasSalaryPermission,
    hasStockPermission: companySettings.hasStockPermission,
    defaultFreightTaxRatePreference:
      companySettings.defaultFreightTaxRatePreference,
    overrideSalesTaxRatePreference:
      companySettings.overrideSalesTaxRatePreference,
    includePosClockinsPreference: companySettings.includePosClockinsPreference,
    timeAllowsPaySummaryPreference:
      companySettings.timeAllowsPaySummaryPreference,
    clockinRoundingPreference: companySettings.clockinRoundingPreference,
  }
}

export const exceptionMessageHandler = (
  error: unknown,
  errorMessage?: string,
) => {
  logger.error(error)
  if (error instanceof CoreApiError) {
    enqueueLoadedSnackbar(
      errorMessage ?? error.description,
      'error',
      error.messages,
      error.id,
      error.code,
    )
    return
  }
  enqueueLoadedSnackbar(errorMessage ?? 'The operation has failed', 'error')
}

/**
 * Create query string parameters from object.
 *
 * Note that keys and values are uri encoded before being returned.
 * @param parameters object with key value
 * @returns
 */
export const createQueryString = (parameters: object) => {
  const queryString = Object.entries(parameters)
    .filter(
      ([_, value]) => value !== null && value !== undefined && value !== '',
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')

  return `${queryString}`
}

/**
 * Wrapper for coreApi method calls to check if the current token has
 * expired and refresh it if needed.
 * @param fetchFn - the function to be called
 * @returns Promise<T>
 */
export const fetchWithValidation = async <T>(
  fetchFn: () => Promise<T>,
): Promise<T> => {
  try {
    await checkAndRefreshToken()
    return await fetchFn()
  } catch (error) {
    if (error?.['status'] === 401) {
      const newToken = await checkAndRefreshToken(true)
      if (newToken) {
        return await fetchFn()
      }
    }

    throw error
  }
}
