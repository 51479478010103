import { IUserInfoDTO, UserInfoDTO } from '@loadedhub/core-api-sdk'
import {
  IUserCompanyInfoWithPermissions,
  transformCompanyOnGet,
} from './userCompanyInfoDTO'
import orderBy from 'lodash/orderBy'

// Extended interface to override permissions property
interface IUserSession extends IUserInfoDTO {
  companiesWithMethods: IUserCompanyInfoWithPermissions[]
}

// New class extending UserCompanyInfoDTO and overriding permissions property type
class UserSession extends UserInfoDTO {
  companiesWithMethods: IUserCompanyInfoWithPermissions[] = []

  constructor(data?: UserInfoDTO) {
    super(data)
    if (data?.companies) {
      // Assuming data.permissions is of type string and needs to be parsed to PermissionType[]
      this.companiesWithMethods = orderBy(
        data.companies?.map(transformCompanyOnGet) ?? [],
        [(option: { name: string }) => option.name.toLowerCase()],
        'asc',
      )
    }
  }
}

export type { IUserSession }
export { UserSession }

export const transformUserSessionOnGet = (userSession: UserInfoDTO) => {
  const transformedUserSession: IUserSession = {
    ...userSession,
    companiesWithMethods: orderBy(
      userSession.companies?.map(transformCompanyOnGet) ?? [],
      [(option: { name: string }) => option.name.toLowerCase()],
      'asc',
    ),
  }

  return transformedUserSession
}
