import { logger } from '@/utils'
import { CoreApiError } from '@loadedhub/core-api-sdk'

export const errorHandler = async (response: Response) => {
  const [hasJsonBody, errorBody] = await response
    .clone()
    .json()
    .then((result) => [true, result])
    .catch(async () => {
      if (response.status === 404) return [false, 'URL Not Found']

      return [false, await response.text()]
    })

  logger.error('Error: %o', errorBody)
  if (hasJsonBody && errorBody.id && errorBody.code && errorBody.description) {
    throw new CoreApiError({
      id: errorBody.id,
      code: errorBody.code,
      description: errorBody.description,
      messages: errorBody.messages,
    }) // Return the typed error
  }

  throw new Error('API client exception')
}
