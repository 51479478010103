import { useState, useEffect, FC, ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SlideProps,
  DialogProps,
} from '@mui/material'
import { transitionComponents } from './Transition'
import { FullPageLoader } from '@/components'

type DialogComponentProps = {
  isOpen: boolean
  closeDialog: () => void
  title?: string
  renderContent?: () => ReactNode
  renderFooter?: () => ReactNode
  onOpen?: () => Promise<void> // Async function to call on open
  transitionDirection?: SlideProps['direction']
  dialogProps?: Omit<DialogProps, 'open'> // Omit open prop from DialogProps as it is handled by DialogComponent
}

const DialogComponent: FC<DialogComponentProps> = ({
  isOpen,
  closeDialog,
  title,
  renderContent,
  renderFooter,
  onOpen,
  transitionDirection,
  dialogProps,
}) => {
  const [loading, setLoading] = useState(false)

  const TransitionComponent = transitionComponents[transitionDirection ?? 'up']

  useEffect(() => {
    const loadData = async () => {
      if (isOpen && onOpen) {
        setLoading(true)
        try {
          await onOpen()
        } finally {
          setLoading(false)
        }
      }
    }

    loadData()
  }, [isOpen, onOpen])

  return (
    <Dialog
      {...dialogProps}
      open={isOpen}
      onClose={closeDialog}
      fullWidth
      TransitionComponent={TransitionComponent}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {loading ? <FullPageLoader /> : renderContent?.()}
      </DialogContent>
      {renderFooter && <DialogActions>{renderFooter()}</DialogActions>}
    </Dialog>
  )
}

export default DialogComponent
