import { lazy } from 'react'

export default {
  Connect: {
    Component: lazy(() => import('./connect/Connect.Page')),
  },
  Install: {
    Component: lazy(() => import('./install/Install.Page')),
  },
}
