import { FC } from 'react'

const Messages: FC = () => {
  return (
    <div className="messages-svg-container">
      <svg
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_12248_33894)">
          <path
            d="M21.6667 0H4.33333C3.18406 0 2.08186 0.456546 1.2692 1.2692C0.456546 2.08186 0 3.18406 0 4.33333L0 17.3333C0 18.4826 0.456546 19.5848 1.2692 20.3975C2.08186 21.2101 3.18406 21.6667 4.33333 21.6667H7.475L12.2969 25.7432C12.4925 25.9088 12.7405 25.9996 12.9967 25.9996C13.253 25.9996 13.501 25.9088 13.6966 25.7432L18.525 21.6667H21.6667C22.8159 21.6667 23.9181 21.2101 24.7308 20.3975C25.5435 19.5848 26 18.4826 26 17.3333V4.33333C26 3.18406 25.5435 2.08186 24.7308 1.2692C23.9181 0.456546 22.8159 0 21.6667 0ZM23.8333 17.3333C23.8333 17.908 23.6051 18.4591 23.1987 18.8654C22.7924 19.2717 22.2413 19.5 21.6667 19.5H18.525C18.0129 19.5002 17.5174 19.6817 17.1264 20.0124L13 23.4975L8.87575 20.0124C8.48419 19.6812 7.98785 19.4996 7.475 19.5H4.33333C3.7587 19.5 3.2076 19.2717 2.80127 18.8654C2.39494 18.4591 2.16667 17.908 2.16667 17.3333V4.33333C2.16667 3.7587 2.39494 3.2076 2.80127 2.80127C3.2076 2.39494 3.7587 2.16667 4.33333 2.16667H21.6667C22.2413 2.16667 22.7924 2.39494 23.1987 2.80127C23.6051 3.2076 23.8333 3.7587 23.8333 4.33333V17.3333Z"
            fill="#A5A5A5"
          />
          <path
            d="M7.58333 7.58341H13C13.2873 7.58341 13.5629 7.46928 13.766 7.26611C13.9692 7.06295 14.0833 6.7874 14.0833 6.50008C14.0833 6.21276 13.9692 5.93721 13.766 5.73405C13.5629 5.53088 13.2873 5.41675 13 5.41675H7.58333C7.29602 5.41675 7.02047 5.53088 6.8173 5.73405C6.61414 5.93721 6.5 6.21276 6.5 6.50008C6.5 6.7874 6.61414 7.06295 6.8173 7.26611C7.02047 7.46928 7.29602 7.58341 7.58333 7.58341Z"
            fill="#A5A5A5"
          />
          <path
            d="M18.4167 9.75H7.58333C7.29602 9.75 7.02047 9.86414 6.8173 10.0673C6.61414 10.2705 6.5 10.546 6.5 10.8333C6.5 11.1207 6.61414 11.3962 6.8173 11.5994C7.02047 11.8025 7.29602 11.9167 7.58333 11.9167H18.4167C18.704 11.9167 18.9795 11.8025 19.1827 11.5994C19.3859 11.3962 19.5 11.1207 19.5 10.8333C19.5 10.546 19.3859 10.2705 19.1827 10.0673C18.9795 9.86414 18.704 9.75 18.4167 9.75Z"
            fill="#A5A5A5"
          />
          <path
            d="M18.4167 14.0833H7.58333C7.29602 14.0833 7.02047 14.1974 6.8173 14.4006C6.61414 14.6037 6.5 14.8793 6.5 15.1666C6.5 15.4539 6.61414 15.7295 6.8173 15.9326C7.02047 16.1358 7.29602 16.2499 7.58333 16.2499H18.4167C18.704 16.2499 18.9795 16.1358 19.1827 15.9326C19.3859 15.7295 19.5 15.4539 19.5 15.1666C19.5 14.8793 19.3859 14.6037 19.1827 14.4006C18.9795 14.1974 18.704 14.0833 18.4167 14.0833Z"
            fill="#A5A5A5"
          />
        </g>
        <defs>
          <clipPath id="clip0_12248_33894">
            <rect width="26" height="26" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Messages
