/* eslint-disable @typescript-eslint/no-unused-expressions */

const STATE = {
  enabled: false,
}

/**
 * Enable logging to the console
 */
function enable() {
  STATE.enabled = true
}

function debug(...args) {
  STATE.enabled && console.debug(...args)
}
function error(...args) {
  STATE.enabled && console.error(...args)
}
function info(...args) {
  STATE.enabled && console.info(...args)
}
function trace(...args) {
  STATE.enabled && console.trace(...args)
}
function warn(...args) {
  STATE.enabled && console.warn(...args)
}

export default {
  enable,
  debug,
  error,
  info,
  trace,
  warn,
}
