import { ReactNode } from 'react'
import {
  OptionsObject,
  SnackbarKey,
  closeSnackbar,
  enqueueSnackbar,
} from 'notistack'
import { AlertColor, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Nullable } from '@/models'

declare module 'notistack' {
  interface VariantOverrides {
    //Custom variant name
    loadedSnackbar: {
      //Custom properties sent to the variant
      details?: ReactNode[]
      severity?: AlertColor
      errorId?: string
      errorCode?: number
    }
  }
}

export const enqueueLoadedSnackbar = (
  message: Nullable<string>,
  severity: AlertColor,
  details: string[] = [],
  errorId?: string,
  errorCode?: number,
  options?: OptionsObject,
) => {
  if (!message) return

  enqueueSnackbar(message, {
    variant: 'loadedSnackbar',
    severity: severity,
    details: details,
    errorId: errorId,
    errorCode: errorCode,
    ...options,
  })
}

export const Action = (snackbarId: SnackbarKey | undefined): ReactNode => (
  <IconButton
    onClick={() => {
      closeSnackbar(snackbarId)
    }}
  >
    <CloseIcon />
  </IconButton>
)
