import { FC } from 'react'

const Shifts: FC = () => {
  return (
    <div className="shifts-svg-container">
      <svg
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 26C5.83158 26 0 20.1684 0 13C0 5.83158 5.83158 0 13 0C20.1684 0 26 5.83158 26 13C26 20.1684 20.1684 26 13 26ZM13 2.16667C7.0265 2.16667 2.16667 7.0265 2.16667 13C2.16667 18.9735 7.0265 23.8333 13 23.8333C18.9735 23.8333 23.8333 18.9735 23.8333 13C23.8333 7.0265 18.9735 2.16667 13 2.16667ZM18.4167 13C18.4167 12.4009 17.9324 11.9167 17.3333 11.9167H14.0833V6.5C14.0833 5.90092 13.598 5.41667 13 5.41667C12.402 5.41667 11.9167 5.90092 11.9167 6.5V13C11.9167 13.5991 12.402 14.0833 13 14.0833H17.3333C17.9324 14.0833 18.4167 13.5991 18.4167 13Z"
          fill="#A5A5A5"
        />
      </svg>
    </div>
  )
}

export default Shifts
