import { FC } from 'react'
import {
  WarningAmber,
  SignalWifiConnectedNoInternet4,
} from '@mui/icons-material'
import { Box, Button, Grid, SvgIconProps, Typography } from '@mui/material'

type FullPageErrorProps = {
  handleReload?: () => void
  noInternet?: boolean
}

/**
 * Simple error component that is intended for display in the main content
 * section of the app when an error occurs preventing it being rendered.
 * @returns
 */
const FullPageError: FC<FullPageErrorProps> = ({
  handleReload,
  noInternet,
}: FullPageErrorProps) => {
  const title: string = noInternet
    ? 'No Internet Connection'
    : 'Sorry something unexpected has happened and we can&apos;t load the page you were trying to see.'

  const subtitle: string = noInternet
    ? "It looks like you're not connected to the internet. Please check your connection and try again."
    : 'If this keeps happening please contact our support team.'

  const Icon: FC<SvgIconProps> = (props) => {
    return noInternet ? (
      <SignalWifiConnectedNoInternet4 {...props} />
    ) : (
      <WarningAmber {...props} />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        backgroundColor: 'background.default',
      }}
    >
      <Grid container sx={{ marginTop: '-60px', color: 'text.primary' }}>
        <Grid item xs={12}>
          <Icon sx={{ fontSize: '92px' }} />
        </Grid>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body1">{subtitle}</Typography>
          {handleReload && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleReload}
              sx={{ marginTop: '1rem', backgroundColor: 'primary.main' }}
            >
              Reload Page
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default FullPageError
