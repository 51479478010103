import { IUserCompanyInfoWithPermissions, UserDetails } from '@/models'
import { authService } from '@/services'
import {
  exceptionMessageHandler,
  getAuthorizationData,
  logger,
  removeActiveCompanyId,
  removeAuthorizationData,
  setActiveCompanyId,
} from '@/utils'
import { PaletteMode } from '@mui/material'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

type AppState = {
  colorMode: PaletteMode
  activeCompany: IUserCompanyInfoWithPermissions | undefined
  userDetails: UserDetails | undefined
}

type AppActions = {
  setActiveCompany: (
    activeCompany: IUserCompanyInfoWithPermissions | undefined,
  ) => void

  toggleColorMode: () => void

  login: (username: string, password: string) => Promise<boolean>

  logout: () => Promise<void>

  reset: () => void
}

const initialState: AppState = {
  colorMode:
    (localStorage.getItem('@@loaded/employee/theme/mode') as PaletteMode) ||
    'light',
  activeCompany: undefined,
  userDetails: undefined,
}

/**
 * Overall app state store
 */
const useAppStore = create(
  persist<AppState & AppActions>(
    (set, get) => ({
      ...initialState,

      setActiveCompany: (
        activeCompany: IUserCompanyInfoWithPermissions | undefined,
      ) => {
        if (!activeCompany?.id) {
          removeActiveCompanyId()
          set({ activeCompany: undefined })
          return
        }

        setActiveCompanyId(activeCompany.id)
        set({ activeCompany })
      },

      toggleColorMode: () => {
        const nextColorMode = get().colorMode === 'light' ? 'dark' : 'light'
        localStorage.setItem('@@loaded/employee/theme/mode', nextColorMode)
        set(() => ({
          colorMode: nextColorMode,
        }))
      },

      login: async (username: string, password: string) => {
        try {
          await authService.login(username, password)

          const userSession = await authService.getUserSession()
          const userDetails: UserDetails = {
            hasPassword: userSession.hasPassword,
            firstName: userSession.firstName,
            lastName: userSession.lastName,
            email: userSession.userName,
            companies: userSession.companiesWithMethods || [],
          }

          set(() => ({
            userDetails: userDetails,
          }))
          return true
        } catch (error) {
          exceptionMessageHandler(error, 'Error when trying to log in')
          logger.error('Error logging in:', error)
          return false
        }
      },

      logout: async () => {
        try {
          const authorizationData = getAuthorizationData()
          await authService.logout(authorizationData.refreshToken)
        } catch (error) {
          exceptionMessageHandler(error, 'Unable to disconnect device')
          logger.error('Error logging out:', error)
          return
        }

        removeActiveCompanyId()
        removeAuthorizationData()

        // The auth-store used for persisting the auth data needs to be cleared
        // but we can't do it from within the store itself because it doesn't fully
        // clears all the data so it is done inmmediately after the logout
        // function is called -> useAppStore.persist.clearStorage()

        set(() => ({
          activeCompany: undefined,
          userDetails: undefined,
        }))
      },

      reset: () => set(initialState),
    }),
    {
      name: '@@loaded/employee/auth-store',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const { userDetails, ...rest } = state
        return {
          ...rest,
          userDetails: userDetails
            ? {
                ...userDetails,
                companies: undefined, // Omit companies array
              }
            : undefined,
        }
      },
    },
  ),
)

export default useAppStore
