import { lazy } from 'react'
import HomeLoader from './home/loader'
import MessagesLoader from './messages/loader'
import TeamLoader from './team/loader'
import MyShiftsLoader from './shifts/my-shifts/loader'
import RosterLoader from './shifts/roster/loader'
import GrabAShiftLoader from './shifts/grab-a-shift/loader'
import MyLeaveLoader from './leave/my-leave/loader'
import MyUnavailabilityLoader from './leave/my-unavailability/loader'

export default {
  Home: {
    Component: lazy(() => import('./home/Home.Page')),
    loader: HomeLoader,
  },
  Shifts: {
    Component: lazy(() => import('./shifts/Shifts.Page')),
    MyShifts: {
      Component: lazy(() => import('./shifts/my-shifts/MyShifts.Page')),
      loader: MyShiftsLoader,
    },
    Roster: {
      Component: lazy(() => import('./shifts/roster/Roster.Page')),
      loader: RosterLoader,
    },
    GrabAShift: {
      Component: lazy(() => import('./shifts/grab-a-shift/GrabAShift.Page')),
      loader: GrabAShiftLoader,
    },
  },
  Messages: {
    Component: lazy(() => import('./messages/Messages.Page')),
    loader: MessagesLoader,
  },
  Leave: {
    Component: lazy(() => import('./leave/Leave.Page')),
    MyLeave: {
      Component: lazy(() => import('./leave/my-leave/MyLeave.Page')),
      loader: MyLeaveLoader,
    },
    MyUnavailability: {
      Component: lazy(
        () => import('./leave/my-unavailability/MyUnavailability.Page'),
      ),
      loader: MyUnavailabilityLoader,
    },
  },
  Team: {
    Component: lazy(() => import('./team/Team.Page')),
    loader: TeamLoader,
  },
}
