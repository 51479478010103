import appConfig from '@/appConfig'
import { CoreApiClient } from '@loadedhub/core-api-sdk'
import { getAuthorizationData, getCompanyId } from '@/utils'

// Note that as this client is a singleton, instantiated once when then app is
// loaded, we need to make sure that properties that can change are functions
// that return the current value.
const coreApiClient = new CoreApiClient(
  {
    authToken: () => getAuthorizationData().accessToken,
    companyId: () => getCompanyId(),
  },
  appConfig.mercuryApiUrl,
)

export default coreApiClient
