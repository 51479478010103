import dayjs, { Dayjs } from 'dayjs'
import { useState, useEffect } from 'react'

interface OnlineStatus {
  isOnline: boolean
  lastChecked: Dayjs | null
}

const initialStatus: OnlineStatus = {
  isOnline: navigator.onLine,
  lastChecked: null,
}

export const useOnlineStatus = (): OnlineStatus => {
  const [onlineStatus, setOnlineStatus] = useState<OnlineStatus>(initialStatus)

  useEffect(() => {
    const handleOnline = () => {
      setOnlineStatus({ isOnline: true, lastChecked: dayjs() })
    }

    const handleOffline = () => {
      setOnlineStatus({ isOnline: false, lastChecked: dayjs() })
    }

    // Set initial online status
    setOnlineStatus({ isOnline: navigator.onLine, lastChecked: dayjs() })

    // Add event listeners
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    // Clean up event listeners
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return onlineStatus
}
