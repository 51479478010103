import { forwardRef, useState, useCallback, ReactNode, FC } from 'react'
import {
  Card,
  IconButton,
  Collapse,
  Paper,
  Stack,
  Alert,
  AlertColor,
  Divider,
  Box,
  Typography,
  TypographyProps,
  AlertProps,
} from '@mui/material'
import { CustomContentProps, useSnackbar, SnackbarContent } from 'notistack'
import { Close, ExpandMore, ExpandLess } from '@mui/icons-material'
import { alertDetails } from '@/constants'

type LoadedSnackbarProps = {
  details?: ReactNode[]
  severity?: AlertColor
  fullWidth?: boolean
  allowDismiss?: boolean
  errorId?: string
  errorCode?: number
  messageTypographyProps?: TypographyProps
  alertProps?: AlertProps
} & Partial<CustomContentProps>

const LoadedSnackbar = forwardRef<HTMLDivElement, LoadedSnackbarProps>(
  (
    {
      id,
      message,
      details = [],
      errorId,
      errorCode,
      severity = 'info',
      fullWidth = false,
      allowDismiss = true,
      messageTypographyProps,
      alertProps,
    },
    ref,
  ) => {
    const { closeSnackbar } = useSnackbar()
    const [expanded, setExpanded] = useState(false)

    const isCollapsable = errorId ?? errorCode ?? details.length > 0

    const handleExpandClick = useCallback(() => {
      setExpanded((prevExpanded) => !prevExpanded)
    }, [])

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    return (
      <SnackbarContent ref={ref}>
        <Card
          sx={{
            padding: 0,
            minWidth: '27rem',
            ...(fullWidth && { width: '100%' }),
          }}
        >
          <Alert
            severity={severity}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 0,
              '.MuiAlert-message': {
                width: '100%',
              },

              ...(severity === 'error' && {
                backgroundColor: 'rgb(253, 237, 237)',
                color: 'rgb(95, 33, 32)',
              }),

              ...alertProps?.sx,
            }}
            {...alertProps}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography {...messageTypographyProps}>{message}</Typography>
              <Stack
                direction={'row'}
                spacing={'.5rem'}
                sx={{ marginLeft: '1rem !important', alignItems: 'center' }}
              >
                <IconButton
                  aria-label="Show more"
                  size="small"
                  onClick={handleExpandClick}
                  sx={{ padding: 0, color: 'rgba(0, 0, 0, 0.54)' }}
                >
                  {isCollapsable &&
                    (expanded ? <ExpandLess /> : <ExpandMore />)}
                </IconButton>
                {allowDismiss && (
                  <IconButton
                    size="small"
                    onClick={handleDismiss}
                    sx={{ padding: 0, color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Alert>
          {isCollapsable && (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider />
              <Paper
                sx={{
                  paddingY: '1rem',
                  backgroundColor: alertDetails,
                  borderRadius: 0,
                }}
              >
                <Stack direction={'column'} spacing={1}>
                  {errorId && (
                    <MonospaceTypography>ID: {errorId}</MonospaceTypography>
                  )}
                  {errorCode && (
                    <MonospaceTypography>Code: {errorCode}</MonospaceTypography>
                  )}
                  {details.length > 0 && (
                    <>
                      <MonospaceTypography>Messages</MonospaceTypography>
                      {/* We can use index as key here because details is not something that is going to sorted or re rendered */}
                      {details.map((detail, index) => (
                        <MonospaceTypography key={index}>
                          - {detail}
                        </MonospaceTypography>
                      ))}
                    </>
                  )}
                </Stack>
              </Paper>
            </Collapse>
          )}
        </Card>
      </SnackbarContent>
    )
  },
)

LoadedSnackbar.displayName = 'LoadedSnackbar'

export default LoadedSnackbar

/**
 * This component only intends to avoid setting font family on every single typography in the LoadedSnackbar.
 * It is not intended to be a generic component as we should not change the font-family of the components and
 * that is why it lives inside this file.
 */
const MonospaceTypography: FC<{ children: ReactNode }> = (props) => {
  return (
    <Box fontFamily={'monospace'} sx={{ color: 'black', paddingX: '1rem' }}>
      {props.children}
    </Box>
  )
}
