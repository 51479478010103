import { createBrowserRouter } from 'react-router-dom'
import { Routes, AuthRoutes, CommonRoutes } from './components/Routes'
import { IUserCompanyInfoWithPermissions } from './models'

export default (activeCompany: IUserCompanyInfoWithPermissions | undefined) =>
  createBrowserRouter(
    [...CommonRoutes, ...(activeCompany ? Routes : AuthRoutes)],
    {
      future: {
        // Check https://reactrouter.com/en/6.27.0/upgrading/future#v7_partialhydration
        v7_partialHydration: true,
      },
    },
  )
