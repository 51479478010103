import { CompanyPreference, PermissionType } from '@/constants'
import {
  IUserCompanyInfoDTO,
  UserCompanyInfoDTO,
} from '@loadedhub/core-api-sdk'

// Extended interface to override permissions property
interface IUserCompanyInfoWithPermissions extends IUserCompanyInfoDTO {
  permissionsList: PermissionType[]
}

// New class extending UserCompanyInfoDTO and overriding permissions property type
class UserCompanyInfoWithPermissions extends UserCompanyInfoDTO {
  permissionsList: PermissionType[] = []

  constructor(data?: IUserCompanyInfoDTO) {
    super(data)
    if (data?.permissions) {
      this.permissionsList = data.permissions.split(',') as PermissionType[]
    }
  }
}

export type { IUserCompanyInfoWithPermissions }
export { UserCompanyInfoWithPermissions }

export const transformCompanyOnGet = (company: IUserCompanyInfoDTO) => {
  const transformedCompany: IUserCompanyInfoWithPermissions = {
    ...company,
    preferences: company.preferences
      ? Object.keys(company.preferences).reduce(
          (acc, key) => {
            if (!company.preferences) return acc

            acc[key as CompanyPreference] =
              company.preferences[key as CompanyPreference]
            return acc
          },
          {} as { [key in CompanyPreference]: string },
        )
      : undefined,
    permissionsList:
      (typeof company.permissions === 'string'
        ? ((company.permissions as unknown as string).split(
            ',',
          ) as PermissionType[])
        : company.permissions) || [],
  }
  return transformedCompany
}
