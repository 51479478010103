import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { isDebugEnabled, logger, reportWebVitals } from './utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import './index.css'
import App from './App'
import { CssBaseline } from '@mui/material'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { LicenseInfo } from '@mui/x-license-pro'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)
dayjs.extend(advancedFormat)

if (isDebugEnabled()) {
  logger.enable()
}

LicenseInfo.setLicenseKey(
  'cd69c388ff4910b952fcb8bc61ee5503Tz03OTk3OCxFPTE3MzMzNzY0OTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <CssBaseline />
    <App />
  </StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
