import { FC } from 'react'

const Home: FC = () => {
  return (
    <div className="home-svg-container">
      <svg
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.0478 10.1786L16.8307 1.64433C15.8137 0.591317 14.4361 0 13 0C11.5639 0 10.1863 0.591317 9.16935 1.64433L0.952263 10.1786C0.649391 10.4911 0.409264 10.8629 0.245802 11.2725C0.0823412 11.6821 -0.00120293 12.1213 1.30861e-05 12.5647V23.6088C1.30861e-05 24.5039 0.342423 25.3624 0.951916 25.9953C1.56141 26.6283 2.38806 26.9838 3.25001 26.9838H22.75C23.612 26.9838 24.4386 26.6283 25.0481 25.9953C25.6576 25.3624 26 24.5039 26 23.6088V12.5647C26.0012 12.1213 25.9177 11.6821 25.7542 11.2725C25.5908 10.8629 25.3506 10.4911 25.0478 10.1786ZM16.25 24.7338H9.75001V20.3081C9.75001 19.413 10.0924 18.5545 10.7019 17.9216C11.3114 17.2887 12.1381 16.9331 13 16.9331C13.862 16.9331 14.6886 17.2887 15.2981 17.9216C15.9076 18.5545 16.25 19.413 16.25 20.3081V24.7338ZM23.8333 23.6088C23.8333 23.9072 23.7192 24.1933 23.516 24.4043C23.3129 24.6153 23.0373 24.7338 22.75 24.7338H18.4167V20.3081C18.4167 18.8162 17.846 17.3855 16.8302 16.3306C15.8144 15.2757 14.4366 14.6831 13 14.6831C11.5634 14.6831 10.1857 15.2757 9.16985 16.3306C8.15403 17.3855 7.58335 18.8162 7.58335 20.3081V24.7338H3.25001C2.9627 24.7338 2.68715 24.6153 2.48398 24.4043C2.28082 24.1933 2.16668 23.9072 2.16668 23.6088V12.5647C2.16768 12.2666 2.28172 11.9808 2.4841 11.7693L10.7012 3.23845C11.3119 2.60725 12.1384 2.25289 13 2.25289C13.8616 2.25289 14.6882 2.60725 15.2988 3.23845L23.5159 11.7727C23.7175 11.9834 23.8315 12.2677 23.8333 12.5647V23.6088Z"
          fill="#A5A5A5"
        />
      </svg>
    </div>
  )
}

export default Home
