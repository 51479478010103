import FullPageError from '@/components/common/app/FullPageError'
import { Component, ErrorInfo, ReactNode } from 'react'
import logger from './logger'

type ErrorBoundaryProps = {
  children?: ReactNode
  fallback?: ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Send the error to the logger
    logger.error('Unexpected React render error %o', error, errorInfo)
  }

  handleReload = () => {
    window.location.reload()
  }

  render() {
    if (!this.state.hasError) return this.props.children

    if (this.props.fallback) return this.props.fallback

    return <FullPageError handleReload={this.handleReload} />
  }
}

export default ErrorBoundary
