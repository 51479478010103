import { QueryClient } from '@tanstack/react-query'

export const singletonQueryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //The concept of "stale time" enables us to define a duration during which React Query
      //regards the existing data as fresh, postponing a server request until that time elapses.
      //This approach enhances the overall performance of our application and lessens the server
      //load by minimizing unnecessary requests within the specified time frame.
      //This a global config but you can also define a staleTime per API call using the `options`
      //parameter in the mercuryApi.get method or useMercuryFetch custom hook.
      staleTime: 1000, //1 second
    },
  },
})
