import { BOTTOM_NAV_HEIGHT } from '@/constants'
import { Box } from '@mui/material'
import { memo, FC, PropsWithChildren } from 'react'

// Memoized content wrapper
export const ContentWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      marginBottom: BOTTOM_NAV_HEIGHT,
      height: '100%',
      backgroundColor: 'lightgray',
      border: '2px solid red',
    }}
  >
    {children}
  </Box>
)

export default memo(ContentWrapper)
