import { FC } from 'react'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'

/**
 * Simple loading component that is intended for display in the main content
 * section of the app when waiting for route data to load.
 * @returns
 */
const FullPageLoader: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'background.default',
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default FullPageLoader
