import { ComponentType, Suspense } from 'react'
import FullPageLoader from './FullPageLoader'

// Wrap your route components with Suspense
const withSuspense = (Component: ComponentType) => (
  <Suspense fallback={<FullPageLoader />}>
    <Component />
  </Suspense>
)

export default withSuspense
