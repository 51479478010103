import { CompanySettings } from '@/models'
import useAppStore from '@/state/store'
import { constructActiveCompanySettings } from '@/utils'

export function useCompanySettings(): CompanySettings {
  const activeCompany = useAppStore((state) => state.activeCompany)
  const companySettings = constructActiveCompanySettings(activeCompany)

  return {
    address: activeCompany?.address,
    name: activeCompany?.name,
    permissionsList: activeCompany?.permissionsList || [],
    pos: activeCompany?.pos,
    invoiceEmail: activeCompany?.invoiceEmail,
    countryPreference: companySettings.countryPreference,
    weekStartPreference: companySettings.weekStartPreference,
    dayStartPreference: companySettings.dayStartPreference,
    localePreference: companySettings.localePreference,
    timeZonePreference: companySettings.timeZonePreference,
    payrollSystemPreference: companySettings.payrollSystemPreference,
    hasPayrollPermission: companySettings.hasPayrollPermission,
    hasManagerPermission: companySettings.hasManagerPermission,
    hasTimePermission: companySettings.hasTimePermission,
    hasSalaryPermission: companySettings.hasSalaryPermission,
    hasStockPermission: companySettings.hasStockPermission,
    defaultFreightTaxRatePreference:
      companySettings.defaultFreightTaxRatePreference,
    overrideSalesTaxRatePreference:
      companySettings.overrideSalesTaxRatePreference,
    includePosClockinsPreference: companySettings.includePosClockinsPreference,
    timeAllowsPaySummaryPreference:
      companySettings.timeAllowsPaySummaryPreference,
    clockinRoundingPreference: companySettings.clockinRoundingPreference,
  }
}
