import { BottomNav as MemoizedBottomNav, FullPageError } from '@/components'
import { useOnlineStatus } from '@/hooks'
import { Stack } from '@mui/material'
import { FC, memo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ContentWrapper } from './ContentWrapper'

const AppLayout: FC = () => {
  const { isOnline } = useOnlineStatus()
  const location = useLocation()

  if (!isOnline) {
    return <FullPageError noInternet />
  }

  return (
    <Stack
      sx={{
        height: '100%',
        backgroundColor: 'background.default',
        overflow: 'hidden',
      }}
    >
      <ContentWrapper>
        <Outlet key={location.pathname} />
      </ContentWrapper>
      <MemoizedBottomNav key={'bottom-nav'} />
    </Stack>
  )
}

export default memo(AppLayout)
