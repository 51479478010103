import { AuthorizationData } from '@/models'
import { AuthError } from './customErrors'
import dayjs, { Dayjs } from 'dayjs'
import { refreshAccessToken } from '@/services/auth'

/** activeCompanyId management */
export const getCompanyId = (): string => {
  const rawCompanyId = localStorage.getItem('@@loaded/employee/activeCompanyId')
  if (rawCompanyId) {
    const companyId = JSON.parse(rawCompanyId)
    if (!companyId) {
      throw new AuthError('Unable to read stored company id')
    }
    return companyId
  }

  return ''
}

export const setActiveCompanyId = (companyId: string): void => {
  localStorage.setItem(
    '@@loaded/employee/activeCompanyId',
    JSON.stringify(companyId),
  )
}

export const removeActiveCompanyId = (): void => {
  localStorage.removeItem('@@loaded/employee/activeCompanyId')
}

/** authoriaztionData management */
export const getAuthorizationData = (): AuthorizationData => {
  const rawAuthData = localStorage.getItem(
    '@@loaded/employee/authorizationData',
  )

  if (!rawAuthData) throw new AuthError('Unable to read authorization data')

  const authData = JSON.parse(rawAuthData)

  if (!authData.accessToken)
    throw new AuthError('Unable to read stored access token')
  if (!authData.refreshToken)
    throw new AuthError('Unable to read stored refresh token')

  return authData
}

export const setAuthorizationData = (
  deviceId: string,
  accessToken: string,
  refreshToken: string,
  expiryDate: Dayjs,
): void => {
  localStorage.setItem(
    '@@loaded/employee/authorizationData',
    JSON.stringify({
      deviceId,
      accessToken,
      refreshToken,
      expiryDate: expiryDate.utc().format(),
    }),
  )
}

export const removeAuthorizationData = (): void => {
  localStorage.removeItem('@@loaded/employee/authorizationData')
}

export const checkAndRefreshToken = async (
  forceRefresh: boolean = false,
): Promise<string> => {
  const authorizationData = getAuthorizationData()

  if (!authorizationData?.accessToken) {
    removeAuthorizationData()
    throw new AuthError('Unable to read access token')
  }

  if (!authorizationData?.refreshToken) {
    removeAuthorizationData()
    throw new AuthError('Unable to read refresh token')
  }

  const now = dayjs()
  const expiry = dayjs(authorizationData?.expiryDate)
  const timeToExpiry = expiry.diff(now, 'second')

  if (timeToExpiry < 60 || forceRefresh) {
    // If token will expire in the next 60 seconds
    const data = await refreshAccessToken(authorizationData?.refreshToken)

    // Store new access token, and potentially new refresh token data.
    // Note that a new refresh token is issued only when the original
    // one is nearing expiry. The deviceId remains the same through
    // the refresh process.
    setAuthorizationData(
      authorizationData?.deviceId,
      data.accessToken,
      data.refreshToken || authorizationData?.refreshToken,
      data.expiryDate,
    )
    return data.accessToken
  }

  return authorizationData?.accessToken
}
