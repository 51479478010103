import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { FC, memo, SyntheticEvent } from 'react'
import { BOTTOM_NAV_HEIGHT } from '@/constants'
import { Home, Leave, Messages, Shifts, Team } from '../display'

const BottomNav: FC = () => {
  const location = useLocation()
  const currentTab =
    location.pathname.split('/').length > 1
      ? '/' + location.pathname.split('/')[1]
      : '/home'
  const navigate = useNavigate()

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    if (currentTab === newValue) return

    navigate(newValue)
  }

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: BOTTOM_NAV_HEIGHT,
      }}
      key={'bottom-nav'}
      elevation={3}
    >
      <BottomNavigation value={currentTab} onChange={handleChange} showLabels>
        <BottomNavigationAction
          label="Home"
          value="/home"
          icon={<Home />}
          disableRipple
        />
        <BottomNavigationAction
          label="Shifts"
          value="/shifts"
          icon={<Shifts />}
          disableRipple
        />
        <BottomNavigationAction
          label="Messages"
          value="/messages"
          icon={<Messages />}
          disableRipple
        />
        <BottomNavigationAction
          label="Leave"
          value="/leave"
          icon={<Leave />}
          disableRipple
        />
        <BottomNavigationAction
          label="Team"
          value="/team"
          icon={<Team />}
          disableRipple
        />
      </BottomNavigation>
    </Paper>
  )
}

export default memo(BottomNav)
