import { logger } from '@/utils'
import { defer } from 'react-router-dom'

export default async function loader() {
  logger.debug('[loader] My Unavailability loader')

  const fetchData = new Promise((resolve) => setTimeout(resolve, 2000)).then(
    () => console.log('My Unavailability fetch data finished'),
  )

  return defer({
    data: Promise.all([fetchData])
      .then(() => true)
      .catch((error) => {
        logger.error('My Unavailability Promise.all error', error)
        return false
      }),
  })
}
