import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'
import DialogComponent from './DialogComponent'
import { DialogOptions } from './dialogOptions'

type DialogContextProps = {
  openDialog: (options: DialogOptions) => void
  closeDialog: () => void
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined)

export const DialogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogOptions, setDialogOptions] = useState<DialogOptions | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const openDialog = useMemo(
    () => (options: DialogOptions) => {
      setDialogOptions(options)
      setIsOpen(true)
    },
    [],
  )

  const closeDialog = useMemo(
    () => () => {
      setIsOpen(false)
    },
    [],
  )

  const value = useMemo(
    () => ({
      openDialog,
      closeDialog,
    }),
    [openDialog, closeDialog],
  )

  return (
    <DialogContext.Provider value={value}>
      {children}
      {dialogOptions && (
        <DialogComponent
          isOpen={isOpen}
          closeDialog={closeDialog}
          {...dialogOptions}
        />
      )}
    </DialogContext.Provider>
  )
}

export const useDialogContext = (): DialogContextProps => {
  const context = useContext(DialogContext)
  if (!context) throw new Error('useDialog must be used within DialogProvider')
  return context
}
